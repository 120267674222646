import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`account`}</li>
      <li parentName="ul">{`contact`}</li>
      <li parentName="ul">{`campaign`}</li>
      <li parentName="ul">{`lead`}</li>
      <li parentName="ul">{`opportunity`}</li>
      <li parentName="ul">{`task`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      